//@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
//@import "../node_modules/@syncfusion/ej2-angular-navigations/styles/material.css";
//@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
//@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css"; // Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// font-family:"Raleway", Source+Sans+Pro, "Helvetica Neue", sans-serif;
$procuro-mo-typography: mat-typography-config(
  $font-family: "Raleway",
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500),
);
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($procuro-mo-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// @todo : try to use CSS variables in material typography !!!
$lidix-primary: (
  Main: #62c0b7,
  Light: #ebf4f3,
  Dark: #62c0b7,
  contrast: (
    Main: #f5f5f5,
    Light: #000,
    Dark: #fff,
  ),
);
$lidix-accent: (
  Main: #62c0b7,
  Light: #ebf4f3,
  Dark: #62c0b7,
  contrast: (
    Main: #f5f5f5,
    Light: #000,
    Dark: #fff,
  ),
);
$lidix-warn: (
  Main: #dd6047,
  Light: #efc1b7,
  Dark: #dd6047,
  contrast: (
    Main: #f5f5f5,
    Light: #000,
    Dark: #fff,
  ),
);
$procuro-mo-primary: mat-palette($lidix-primary, Main, Light, Dark);
$procuro-mo-accent: mat-palette($lidix-accent, Main, Light, Dark);
$procuro-mo-warn: mat-palette($lidix-warn, Main, Light, Dark);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$procuro-mo-theme: mat-light-theme(
  (
    color: (
      primary: $procuro-mo-primary,
      accent: $procuro-mo-accent,
      warn: $procuro-mo-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($procuro-mo-theme);

// @import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons+Outlined");
/*add axa*/
/*Get ui-kit for "icons as font" from fontawesome*/
@import url("https://use.fontawesome.com/releases/v5.7.1/css/all.css");

@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800");

:root {
  --color: var(--custom--color, #333333);
  --bg-color: var(--custom--bg-color, #fafafa);
  --fog-color: var(--custom--fog-color, #a8afbc);
  --font-family: "Raleway";
  --font-size: 1rem;
  --font-weight: 400;
  --shadow-color: var(--custom--shadow-color, #f7f7f7);
  --color-menu: var(--custom--color-menu, #f7f7f7);
  --color-validate: var(--custom--color-validate, #62c0b7);
  --bg-color-validate: var(--custom--bg-color-validate, #ebf4f3);
  --header-color-lg-start: var(--custom--header-color-lg-start, #62c0b7);
  --header-color-lg-end: var(--custom--header-color-lg-end, #8f66e1);
  --btn--bg-color: var(--custom--btn--bg-color, white);
  --ui-width: 100%;
  --ui-max-width: 1170px;
  --color--btn-validate: var(--custom--color--btn-validate, #62c0b7);
  --color--btn-bg-validate: var(--custom--color--btn-bg-validate, #ffffff);
  --color--btn-disabled: var(--custom--btn-disabled, #a8afbc);
  --color--btn-bg-disabled: var(--custom--color--btn-bg-disabled, #e0e0e0);
  --color--btn-important: var(--custom--btn-important, #dd6047);
  --color--btn-bg-important: var(--custom--color--btn-bg-important, #ffffff);
  --color--btn-cancel: var(--custom--btn-cancel, grey);
  --color--btn-bg-cancel: var(--custom--color--btn-bg-cancel, #ffffff);
  --color--btn-bg-close: var(--custom--color--btn-bg-close, #55ad9f);
  --color--matIcon: var(--custom--color--matIcon, #919191);
  --color--matIcon-disabled: var(--custom--color--matIcon-disabled, #a8afbc);
  --color--menuLi-hover: var(--custom--color--menuLi-hover, #fff);
  --table-bg-color-hover: var(--custom--table-bg-color-hover, #d3d3d3);
  --table-bg-color-even: var(--custom--table-bg-color-even, white);
  --table-bg-color-odd: var(--custom--table-bg-color-odd, #f2f2f2);
  --color-pink: #ee957d;
  --color-light-green: #ebf4f3;
  --color-green: #daeeec;
  --color-dark-green: #68b04d;
  --color-dark-gray: #525252;
  --color-text-gray: #919191;
  --color-light-gray: #f0f0f0;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-red: #fc1000;
  --color-saumon: #f3d5cf;
  --color-violet: #c7c5ed;
}
$pink: #ee957d;
// #############" 1. HTML STANDARD ELEMENTS ###################

/*#region */

html {
  scroll-behavior: smooth;
}

body {
  text-rendering: geometricPrecision;
  color: #525252;
  font-family: sans-serif; // fallback
  font-family: var(--font-family);
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;
}
table {
  width: 100%;
}
/*#endregion*/

/*
  *********************************************
  2. CLASSES GENERAL
  *********************************************
  */

.content * {
  color: var(--color-black);
}

.col-1 {
  width: calc(100% / 12 * 1);
}

.col-2 {
  width: calc(100% / 12 * 2);
}

.col-3 {
  width: calc(100% / 12 * 3);
}

.col-4 {
  width: calc(100% / 12 * 4);
}

.col-5 {
  width: calc(100% / 12 * 5);
}

.col-6 {
  width: calc(100% / 12 * 6);
}

.col-7 {
  width: calc(100% / 12 * 7);
}

.col-8 {
  width: calc(100% / 12 * 8);
}

.col-9 {
  width: calc(100% / 12 * 9);
}

.col-10 {
  width: calc(100% / 12 * 10);
}

.col-11 {
  width: calc(100% / 12 * 11);
}

.col-12 {
  width: calc(100% / 12 * 12);
}

.mat-menu-content {
  background-color: white;
}
.matMenu > button {
  color: var(--color-black);
}
/*
 *********************
 ROUTER -OUTLET
 *********************
 */

router-outlet {
  position: fixed;
}

/*
 *********************
 APP LAYOUT - NAV BAR
 *********************
 */

/*#region */

app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

app-layout > * > * {
  width: var(--ui-width);
  max-width: var(--ui-max-width);
  margin-left: auto;
  margin-right: auto;
}

app-layout > main {
  flex: 1;
  --menu-minWidth: 256px;
  --menu-iconWidth: 48px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  position: relative;
  /*needs for absolute positionning embed*/
}

app-layout main > div > div.rmenu > div.menu > ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

app-layout > main > div > div.rmenu {
  width: var(--menu-iconWidth);
  min-height: 100vh;

  background-color: var(--color-menu);
}

app-layout main > div > div.rmenu > div.menu li {
  display: block;
  min-height: var(--menu-iconWidth);
  display: flex;
  align-items: center;
}

app-layout > main > div > div.rmenu:hover > .menu {
  width: var(--menu-minWidth);
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  font-size: var(--font-size);
  background-color: var(--color-menu);
}

app-layout main > div > div.rmenu > div.displayExpandOn li:hover {
  background-color: var(--color--menuLi-hover);
}

app-layout main > div > div.rmenu > div.displayExpandOff2 li:hover {
  background-color: var(--color--menuLi-hover);
}

app-layout main > div > div.rmenu > div.menu li mat-icon {
  display: inline-block;
  text-align: center;
  width: var(--menu-iconWidth) !important;
  flex-shrink: 0;
}

app-layout main > div > div.rmenu > div.menu li label {
  display: inline-block;
  line-height: unset;
  margin: 0;
  width: 100%;
  padding-right: 1rem;
}

app-layout main > div > div.rmenu > div.displayExpandOn {
  box-shadow: 1px 1px 1px var(--fog-color);
  background-color: var(--color-menu);
  width: var(--menu-minWidth);
  height: 100%;
  position: relative;
  z-index: 10;
  font-size: var(--font-size);
  background-color: var(--color-menu);
}

app-layout main > div > div.rmenu > div.displayExpandOff2 li > span,
app-layout main > div > div.rmenu > div.displayExpandOff2 li > label {
  display: none;
}

app-layout main > div > div.rmenu > div.displayExpandOff2:hover li > span,
app-layout main > div > div.rmenu > div.displayExpandOff2:hover li > label {
  display: inline-block;
}

app-layout main > div > div.rmenu > div.displayExpandOff2 li:hover > label,
app-layout main > div > div.rmenu > div.displayExpandOff2 li:hover > span,
app-layout main > div > div.rmenu > div.displayExpandOn label {
  display: inline-block;
}

app-layout > main.account > div {
  flex: auto;
  display: flex;
  padding-bottom: 2rem;
}

app-layout > main.account > div > * {
  flex: 1;
}

/*
Public pages header
*/
app-layout > header {
  height: calc(var(--font-size) * 5);
  display: flex;
}

app-layout > header * {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

app-layout > header.account {
  /* @origin: procuro-mvp/theme.css#homePanelHolder */
  height: var(--header--min-height);
  transform-style: preserve-3d;
  backface-visibility: hidden;

  // @origin: axa
  height: calc(var(--font-size) * 5);
  font-family: sans-serif;

  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;

  background: linear-gradient(
      to right,
      var(--header-color-lg-start) 1%,
      var(--header-color-lg-end) 100%
    )
    left bottom #fff no-repeat;
  background-size: 100% 3px;

  display: flex;
}

app-layout > header > app-header > nav {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
}

app-layout > header > app-header > nav > div:last-child {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

app-layout > header > app-header > nav > * {
  height: calc(var(--font-size) * 3.5);
  align-self: center;
}

app-layout > header > app-header > nav > div > ul {
  align-self: center;
  list-style: none;
  display: flex;
  flex-direction: row;
}

app-layout > header > app-header > nav > div > ul > li {
  position: relative;
  color: var(--fog-color);
  margin-left: calc(var(--font-size) * 0.5);
}
/*#endregion */
/*#endregion */

app-register .register-form {
  padding: calc(var(--font-width) * 2);
  width: calc(100vw / 2);
  display: flex;
  flex-direction: column;
}

/*
 *********************
 MAT-CARD -TABLE
 *********************
 */
/*#region */
mat-card > table {
  width: 100%;
}
/*#endregion */

/*
 *********************
LIST
 *********************
 */
/*#region */
app-list-view-header {
  margin-bottom: calc(var(--font-size) * 2);
}
/*#endregion */

// ***********LIDIX-CLASS************

.customerLogo,
.customerLogo svg {
  width: calc(var(--font-size) * 3.5);
  height: calc(var(--font-size) * 3.5);
}

.customerLogo img {
  height: calc(var(--font-size) * 3.5);
}

.environment {
  display: flex;
  justify-content: end;
  font-style: italic;
  color: var(--color-menu);
}

.commonForm {
  background-color: rgba(0, 0, 0, 0.1);
  max-width: 500px;
  // padding: 40px 40px 40px !important;
  margin: calc(var(--font-size) * 2) auto calc(var(--font-size) * 2) auto;
  // border-radius: 0 !important;
}

.commonForm mat-form-field {
  width: 100%;
}
section.account-form {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
// .commonForm h2 {
//   font-size: 2rem;
//   line-height: 1.2;
//   font-weight: 500;
// }

.buttonBar {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fc1000;
  font-style: italic;
}
.mat-dialog-content.stl-mat-dialog-content > .pos-col-2-form-field {
  display: flex;
  width: 30vw;
  align-items: center;
  justify-content: space-around;
}
.mat-dialog-content.stl-mat-dialog-content > .pos-col-1-form-field {
  // display: flex;
  width: 30vw;
  align-items: center;
  justify-content: space-around;
}
table.stl-tax-resume {
  margin-top: auto;
  width: 30vw;
}
.stl-amount {
  text-align: right;
}
nav mat-icon {
  font-size: 2rem !important;
}

button[type="validate"],
button[type="submit"] {
  color: var(--color--btn-validate);
  background-color: var(--color--btn-bg-validate);
  font-size: var(--font-size);
  font-family: var(--font-family);
  border: 2px solid var(--color--btn-validate);
  border-radius: calc(var(--font-size) * 1.5);
  padding: calc(var(--font-size) * 0.75) calc(var(--font-size) * 0.75)
    calc(var(--font-size) * 0.75) calc(var(--font-size) * 0.75);
  transition: all 0.2s linear;
  line-height: 1;

  &:hover {
    color: var(--color--btn-bg-validate);
    background-color: var(--color--btn-validate);
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
  }
}

button[type="important"] {
  font-size: var(--font-size);
  color: var(--color--btn-important);
  background-color: var(--color--btn-bg-important);
  border: 2px solid var(--color--btn-important);
  border-radius: calc(var(--font-size) * 1.5);
  padding: calc(var(--font-size) * 0.75) calc(var(--font-size) * 0.75)
    calc(var(--font-size) * 0.75) calc(var(--font-size) * 0.75);
  transition: all 0.2s linear;
  line-height: 1;
  &:hover {
    color: var(--color--btn-bg-important);
    background-color: var(--color--btn-important);
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
  }
}

button[type="disabled"] {
  font-size: var(--font-size);
  color: var(--color--btn-disabled);
  background-color: var(--color--btn-bg-disabled);
  border: 2px solid var(--color--btn-disabled);
  border-radius: calc(var(--font-size) * 1.5);
  padding: calc(var(--font-size) * 0.75) calc(var(--font-size) * 0.75)
    calc(var(--font-size) * 0.75) calc(var(--font-size) * 0.75);
  line-height: 1;

  &:hover {
    cursor: not-allowed;
  }
}

button[type="cancel"],
button[type="reset"] {
  font-size: var(--font-size);
  font-weight: 500;
  color: var(--color--btn-cancel);
  background-color: var(--color--btn-bg-cancel);
  border: 2px solid transparent;
  border-radius: calc(var(--font-size) * 1.5);
  padding: calc(var(--font-size) * 0.75) calc(var(--font-size) * 0.75)
    calc(var(--font-size) * 0.75) calc(var(--font-size) * 0.75);
  transition: all 0.2s linear;
  line-height: 1;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
  outline: 0;

  &:hover {
    border: 2px solid var(--color--btn-validate);
  }
}

button[type="close"] {
  font-size: var(--font-size);
  color: var(--color--btn-bg-validate);
  background-color: var(--color--btn-validate);
  border: 2px solid var(--color--btn-validate);
  border-radius: calc(var(--font-size) * 1.5);
  padding: calc(var(--font-size) * 0.75) calc(var(--font-size) * 0.75)
    calc(var(--font-size) * 0.75) calc(var(--font-size) * 0.75);
  transition: all 0.2s linear;
  line-height: 1;

  &:hover {
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
    background-color: var(--color--btn-bg-validate);
    color: var(--color--btn-validate);
  }
}

button[type="actions"] {
  color: var(--color--matIcon);
  background-color: white;
  border: unset;
  cursor: pointer;

  &:hover {
    color: var(--color--btn-important);
  }
}

button[type="actions"]:disabled {
  color: var(--color--matIcon-disabled);
  cursor: not-allowed;
}

input[type="radio"] {
  display: none;
}

input[type="radio"].button-radio:checked + label {
  color: #fff;
  background-color: var(--color--btn-validate);
  border: 2px solid var(--color--btn-validate);
}

input[type="radio"].button-radio + label:hover {
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
}

input[type="radio"].button-radio + label,
input[type="radio"].button-radio:disabled + label {
  position: relative;
  padding: calc(var(--font-size) * 0.25) calc(var(--font-size) * 1)
    calc(var(--font-size) * 0.25) calc(var(--font-size) * 1);
  color: var(--fog-color);
  display: inline-block;
  border-radius: calc(var(--font-size) * 2);
  border: 2px solid #eaeceb;
}

input[type="radio"][value="refuse"].button-radio:checked + label {
  color: #fff;
  background-color: #dd6047;
  border: 2px solid #dd6047;
}

input[type="radio"][value="refuse"].button-radio + label:hover {
  border: 2px solid #dd6047;
}

input[type="radio"][value="accept"].button-radio:checked + label {
  color: var(--color--btn-bg-validate);
  background-color: var(--color--btn-validate);
  border: 2px solid var(--color--btn-validate);
}

input[type="radio"][value="accept"].button-radio + label {
  background-color: var(--color--btn-bg-validate);
  color: var(--color--btn-validate);
  border: 2px solid var(--color--btn-validate);
}

.table-currency-col-header {
  .mat-sort-header-container {
    justify-content: flex-end;
    text-align: right;
  }
  .mat-sort-header-content {
    text-align: right;
  }
}
th.table-currency-col.mat-header-cell,
td.table-currency-col.mat-cell,
td.table-currency-col.mat-footer-cell {
  margin-right: var(--font-size);
  text-align: right;
}
.panel-table {
  width: 100%;
}

.headerCard .mat-card-content {
  display: flex;
  flex-direction: row;
  padding-top: calc(var(--font-size) * 0.5);
  margin-bottom: 0;
  font-size: calc(var(--font-size) * 0.9);
  justify-content: flex-end;
}

mat-card.headerCard div.titleMenu {
  display: flex;
  flex-direction: row;
}

mat-card.headerCard div.titleMenu > h6 {
  margin: 2vh 1vh auto 1vh;
  font-style: italic;
  color: var(--fog-color);
  font-size: 0.955rem;
}

mat-card.headerCard div.actionMenu {
  position: relative;
  display: flex;
  flex-direction: column;
}

mat-card.headerCard div.actionMenu > div.subMenu {
  display: none;
  position: absolute;
}

mat-card.headerCard div.actionMenu:hover > div.subMenu {
  position: absolute;
  top: 2rem;
  left: 7rem;
  background-color: white;

  display: flex;
  flex-direction: column;
  z-index: 20;

  border-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

mat-card.headerCard div.actionMenu:hover > div.subMenu > div {
  white-space: nowrap;
  font-size: calc(var(--font-size) * 1.2);
  padding: 8px 16px 8px 16px;
}

mat-card.headerCard div.actionMenu:hover > div.subMenu > div:hover {
  background-color: var(--shadow-color);
}

mat-card.headerCard ul.actionMenu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: sticky;

  display: flex;
  flex-direction: row;
  z-index: 20;
}

mat-card.headerCard ul.actionMenu > li {
  align-items: center;
  white-space: nowrap;
  display: inline-block;
  background-color: var(white);
  margin: 0;
  font-size: calc(var(--font-size) * 0.85);
  width: 100%;
  height: 100%;
}

mat-card.headerCard ul.actionMenu li.divider {
  border-bottom: 1px solid var(--fog-color);
}

mat-card.headerCard ul.actionMenu > li:hover > ul {
  display: inherit;
}

mat-card.headerCard ul.actionMenu ul {
  padding: 0;
  margin: 0;
  display: none;

  position: absolute;
  right: 0;

  list-style: none;
}

mat-card.headerCard ul.actionMenu ul li {
  background-color: var(--shadow-color);
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

mat-card.headerCard ul.actionMenu ul li.disabled {
  color: var(--fog-color);
}

mat-card.headerCard ul.actionMenu > li:hover > ul li:hover {
  background-color: white;
  cursor: pointer;
}

mat-card.headerCard ul.actionMenu > li:hover > ul li.disabled:hover {
  cursor: not-allowed;
}

mat-card.headerCard ul.actionMenu ul li {
  position: relative;
}

mat-card.headerCard .mat-card-title {
  display: flex;
  justify-content: center;
  font-size: calc(var(--font-size) * 1.6);
  font-weight: 500;
}

mat-card.headerCard mat-card-title > div:first-child {
  flex-grow: 1;
}

mat-card.headerCard
  > form
  > div
  > mat-card-content
  > div.datePadding
  .mat-form-field-appearance-legacy
  .mat-form-field-wrapper {
  padding-bottom: 1.2em;
}

mat-card.headerCard > form > div > mat-card-content > div.datePadding {
  padding-right: 1vw;
}

mat-card.headerCard
  > form
  > div
  > mat-card-content
  > div.col-2.headerExpandPadding {
  padding-right: 1vw;
}

.form {
  margin-top: 2rem;
  color: #525252;
  display: flex;
  flex-direction: column;
}

.row-form > mat-radio-group > mat-radio-button {
  margin-right: 15px;
}

.row-form > .mat-form-field + .mat-form-field {
  margin-left: 80px;
}

mat-card.form > form > div {
  display: flex;
  flex-direction: row;
}

.mat-card > form > div > div {
  padding-right: 30px;
}

app-view > mat-card.form > form > div mat-form-field {
  width: 100%;
}

.form h4 {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
  color: #525252;
}

.form .mat-radio-button:first-child {
  padding-right: 15px;
}

.form mat-radio-group label {
  margin-bottom: 20px;
}

.mat-form-field-appearance-legacy .mat-form-field-label,
.mat-form-field-appearance-fill .mat-form-field-label,
.mat-form-field-appearance-outline .mat-form-field-label,
.mat-form-field-appearance-standard .mat-form-field-label {
  overflow: visible;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #62c0b7;
}

app-popup-register > form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
app-popup-register > form > mat-card.popup-form {
  height: var(--ui-max);
  width: var(--ui-max);
  margin: auto;
}
app-popup-register > form > mat-card.popup-form > div > mat-card-title {
  text-align: center;
}

mat-dialog-container {
  min-height: 50%;
  min-width: 50%;
  margin: auto;
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*  PERSONAL CUSTOMISATION */

mat-card-title h3 {
  font-size: 0.9rem !important;
}

mat-card-title {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: stretch !important;
  flex-wrap: nowrap !important;
}

mat-card-title > div:first-child {
  flex-grow: 1;
  width: unset !important;
}

mat-card-title > button,
button[mat-mini-fab],
button.mat-mini-fab {
  display: flex;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;

  border-radius: var(--btn-mmf--border-radius) !important;

  width: 1.75rem !important;
  height: 1.75rem !important;
  margin-left: 0.5rem;
  padding: 0;
}

mat-card-title > button,
mat-card-title > button > mat-icon,
button[mat-mini-fab] > mat-icon,
button.mat-mini-fab > mat-icon {
  padding: 0 !important;
  padding-top: 0.1rem !important;
  font-size: 1.15rem !important;
}

mat-card-title > button {
  border-radius: 0.875rem !important;
  width: unset !important;
  font-size: 0.9rem !important;
  padding-left: 0.875rem !important;
  padding-right: 0.875rem !important;
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

.draft-card > mat-card-title,
.libfile-card > mat-card-title {
  font-size: 0.9rem !important;
}

// .mat-card > div {
//   margin-top: -40px;
//   margin-left: 950px;
// }
.mat-card-title {
  font-size: calc(var(--font-size) * 1.6) !important;
}
/*
mat-card.dashboard > mat-card-content {
  font-size: 0.9rem !important;
}
*/
mat-card + mat-card {
  margin-top: 2rem !important;
}

.dashboard div {
  max-width: 400px;
  border-color: var(--bg-color--ui-title);
  font-size: 13px;
}

.HeaderCard {
  mat-card-content {
    display: flex;
    justify-content: flex-end;
    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      margin: 0 var(--font-size);
    }
  }
}

.HeaderCard > mat-sidenav-content > button {
  z-index: 4;
  position: absolute;
}

.HeaderCard {
  display: flex !important;

  flex-direction: column;
}

div.actionMenu:hover > div.subMenu > div {
  white-space: nowrap;
  font-size: calc(var(--font-size) * 1.2);
  padding: 8px 16px 8px 16px; //from mat-card
}

div.actionMenu:hover > div.subMenu > div:hover {
  background-color: var(--shadow-color);
}

ul.actionMenu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: sticky;

  display: flex;
  flex-direction: row;
  //position: relative;
  //padding-right:100px;
  z-index: 20;
}

ul.actionMenu > li {
  align-items: center;
  white-space: nowrap;
  display: inline-block;
  background-color: var(white);
  margin: 0;
  font-size: calc(var(--font-size) * 0.85);
  width: 100%;
  height: 100%;
}

ul.actionMenu li.divider {
  border-bottom: 1px solid var(--fog-color);
}

ul.actionMenu > li:hover > ul {
  display: inherit;
}

ul.actionMenu > li:hover > button[type="actions"] > mat-icon {
  color: var(--color-notification);
}

div.action > button[type="actions"] > .mat-icon {
  color: var(--color-text-gray) !important; //@todo change this to var()
}

div.action.notification > button[type="actions"] > .mat-icon {
  color: var(--color-notification) !important;
}

div.action:hover > button[type="actions"] > mat-icon {
  color: var(--color-notification) !important;
}

ul.actionMenu ul {
  padding: 0;
  margin: 0;
  display: none;

  position: absolute;
  right: 0;

  list-style: none;
}

/* Fisrt Tier Dropdown */
/*
*/
ul.actionMenu ul li {
  background-color: var(--shadow-color);
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}
ul.actionMenu ul li.disabled {
  color: var(--fog-color);
}
ul.actionMenu > li:hover > ul li:hover {
  background-color: white; //var(--shadow1-color, #f7f7f7);
  cursor: pointer;
}
ul.actionMenu > li:hover > ul li.disabled:hover {
  //background-color: var(--shadow-color);
  cursor: default;
}
/* Second, Third and more Tiers	*/
/*
*/
ul.actionMenu ul li {
  position: relative;
}

.color-gray {
  color: var(--color-dark-gray);
}

.color-red {
  color: var(--color-red);
}

.color-green {
  color: var(--color-dark-green);
}

.table {
  thead {
    th {
      border-bottom: 1px;
      border-top: 0;
      line-height: normal;
      font-family: "Raleway", sans-serif;
    }
  }

  td,
  th {
    padding: 0.25rem;
    font-family: "Raleway", sans-serif;
  }

  .btn {
    padding: 0.1rem 0.5rem;
    border-radius: 50px !important;
    line-height: normal;
  }
}

input {
  /* Firefox */
  -moz-appearance: textfield;

  /* Chrome */
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Opéra*/
  &::-o-inner-spin-button {
    -o-appearance: none;
    margin: 0;
  }
}

.btn-close-header {
  border: 1px solid var(--color-red);
  color: var(--color-red);
  background-color: transparent;
  border-radius: 3px;
  line-height: 12px;
  padding: 10px;
  height: 10px;
  width: 10px;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  display: flex;
  float: right;
  margin-left: 15px;

  &:hover,
  &:focus,
  &:active {
    color: var(--white);
    background-color: var(--color-disable);
  }
}
.TabMenuItem,
.TabMenuItem a {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-decoration: none;
  padding: 0;
}

.TabMenuItem {
  margin-left: 0.5rem;
  padding-right: 0.25rem;
  padding-left: 0.5rem;
  border-radius: 5px;
  align-items: center;
}

.TabMenuItem.active {
  background-color: lightgray;
}

.CloseTabMenuItem {
  border: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 2px;

  margin-left: 0.25rem;
  //line-height: 12px;
  padding: 0.125rem;

  width: 1.4rem;
  height: 1.4rem;
  font-size: 2rem;
  //For the span element

  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &:hover,
  &:focus,
  &:active {
    //color: var(--white);
    background-color: var(--color-disable);
  }
}

.actionsitem {
  color: var(--color-primary) !important;
  background-color: white;
  pointer-events: none !important;
  padding-top: -5px !important;
  margin-top: -5px !important;
  outline: none !important;
  margin-bottom: -7px !important;
  padding-bottom: -7px !important;
}

.mat-menu-panel {
  max-width: none !important;
  font-size: 0.9rem;
  color: var(--color-text-gray);
  background: var(--bg-color);
  background-color: var(--light-gray);
}

button.mat-menu-item {
  font-size: 0.9rem;
  color: var(--color-text-gray);
}

.has-search {
  .form-control {
    padding: 0.5rem 0 0.5rem 2.375rem;
    border-radius: 50px;
    height: 30px;
  }

  .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    line-height: 30px;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    left: 1.5rem;
  }
}

mat-icon.material-icons {
  text-align: center;
}

main#menu04 > div > div.menu {
  background-color: --color-menu;
}

.CommonDialog {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.commonForm {
  padding: 40px 40px 40px;
  max-width: 500px;
  margin: calc(var(--font-size) * 2) auto calc(var(--font-size) * 2) auto;
  background: rgba(0, 0, 0, 0.1);
  font-family: "Raleway", sans-serif;

  h2 {
    font-size: 2rem;
    display: block;
  }

  .form-group input {
    display: block;
    width: 100%;
    padding: calc(var(--font-size) * 0.25) calc(var(--font-size) * 0.75)
      calc(var(--font-size) * 0.25) calc(var(--font-size) * 0.75);
  }
}

/*
  */
ul.buttonbar {
  display: flex;
  margin: 0 0 0 0 !important;
  padding: 0 0 0 0 !important;
  flex-direction: row;
  list-style: none;
  justify-content: space-between !important;
  padding-bottom: calc(var(--font-size) * 2);
}

ul.buttonbar li {
  padding: 0 0 0 0;
}

ul.buttonbar li::before {
  display: none;
}

ul.buttonbar li a {
  display: block;
  white-space: nowrap;
}

.row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
}

main#menu04 > div {
  height: 100vh;
  --menu-minWidth: 256px;
  --menu-iconWidth: 48px;
}

main#menu04 > div {
  position: sticky;
  display: flex;
}

main#menu04 > div > div.menu {
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: var(--color-menu);
  font-size: 1rem;
  height: 100%;
  width: var(--menu-iconWidth);
}

main#menu04 > div > div:last-child {
  position: absolute;
  top: 0;
  left: calc(var(--menu-iconWidth) * 1.5);
  width: calc(100% - var(--menu-iconWidth));
}

main#menu04 > div > div.menu * {
  white-space: nowrap;
}

main#menu04 > div > div.menu > ul {
  background-color: transparent;

  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

main#menu04 > div > div.menu li {
  background-color: transparent;

  display: block;
  min-height: var(--menu-iconWidth);
  display: flex;
  align-items: center;
}
main#menu04 > div > div.displayExpandOn li {
  background-color: var(--shadow-color);
}

main#menu04 > div > div.menu li:hover {
  color: black;
}
main#menu04 > div > div.displayExpandOn li:hover {
  background-color: var(--bg-color);
}
main#menu04 > div > div.displayExpandOff li:hover {
  background-color: white;
}
main#menu04 > div > div.displayExpandOff2 li:hover {
  background-color: white;
}

main#menu04 > div > div.menu li mat-icon {
  display: inline-block;
  width: var(--menu-iconWidth) !important;
  flex-shrink: 0;
}
main#menu04 > div > div.menu li label {
  display: inline-block;
  line-height: unset;
  margin: 0;
  width: 100%;
  padding-right: 1rem;
}

main#menu04 > div > div.displayExpandOff {
  width: var(--menu-iconWidth);
}
main#menu04 > div > div.displayExpandOff2 {
  width: var(--menu-iconWidth);
}
main#menu04 > div > div.displayExpandOn {
  box-shadow: 1px 1px 1px lightgray;
  width: var(--menu-minWidth);
  min-width: var(--menu-minWidth);
}
main#menu04 > div > div.displayExpandOff:hover {
  min-width: calc(1.5 * var(--menu-minWidth));
}

main#menu04 > div > div.displayExpandOff2:hover {
  min-width: var(--menu-minWidth);
}

main#menu04 > div > div.displayExpandOn li {
  min-width: var(--menu-minWidth);
}

main#menu04 > div > div.displayExpandOff:hover li mat-icon {
  width: var(--menu-iconWidth) !important;
}

main#menu04 > div > div.displayExpandOff li > span,
main#menu04 > div > div.displayExpandOff li > label {
  display: none;
}
main#menu04 > div > div.displayExpandOff2 li > span,
main#menu04 > div > div.displayExpandOff2 li > label {
  display: none;
}
main#menu04 > div > div.displayExpandOff:hover li > span,
main#menu04 > div > div.displayExpandOff:hover li > label {
  display: inline-block;
}
main#menu04 > div > div.displayExpandOff2:hover li > span,
main#menu04 > div > div.displayExpandOff2:hover li > label {
  display: inline-block;
}

ul.expandMenuOff {
  display: none !important;
}
ul.expandMenuOn {
  display: block;
}

main#menu04 > div > div.displayExpandOn > ul.expandMenuOn > li > label:before {
  content: " - ";
}
main#menu04
  > div
  > div.displayExpandOff2
  > ul.expandMenuOn
  > li
  > label:before {
  content: " - ";
}
main#menu04 > div > div.displayExpandOff > ul.expandMenuOn > li > label:before {
  content: " ";
  white-space: pre;
}

main#menu04 > div > div.displayExpandOff2 > ul.expandMenuOn > li:before {
  width: var(--menu-iconWidth);
  height: var(--menu-iconWidth);
  content: " ";
  white-space: pre;
}
main#menu04 > div > div.displayExpandOn > ul.expandMenuOn > li:before {
  width: var(--menu-iconWidth);
  height: var(--menu-iconWidth);
  content: " ";
  white-space: pre;
}

main#menu04 > div > div.displayExpandOff2 ul.expandMenuOn > li > span {
  display: none;
}
main#menu04 > div > div.displayExpandOn ul.expandMenuOn > li > span {
  display: none;
}

main#menu04 > div > div.displayExpandOff li:hover > label,
main#menu04 > div > div.displayExpandOff li:hover > span,
main#menu04 > div > div.displayExpandOff2 li:hover > label,
main#menu04 > div > div.displayExpandOff2 li:hover > span,
main#menu04 > div > div.displayExpandOn label {
  display: inline-block;
}

main#menu04 > div > div.displayExpandOff li:hover > span + label {
  text-transform: lowercase;
}

nav {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  div.title a {
    font-family: Source Sans Pro, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    font-size: calc(var(--font-size) * 1.2);
    font-weight: bold;
    align-self: center;
    padding: calc(var(--font-size) * 0.9) calc(var(--font-size) * 1)
      calc(var(--font-size) * 0.6) calc(var(--font-size) * 1);
    color: var(--axa-color-espaceclient);
  }
  .procuro-logo {
    width: 148px;
    height: auto;
  }
  ul {
    align-self: center;
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    li {
      position: relative;
      color: var(--fog-color);
      margin-left: calc(var(--font-size) * 0.5);
      a {
        text-decoration: none;
        color: var(--fog-color);
        font-size: calc(var(--font-size) * 0.75);
        font-weight: 600;
        white-space: nowrap;
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: calc(var(--font-size) * 1.5);
        span.ideogram {
          margin-top: calc(var(--font-size) * 0.5);
          font-weight: 300;
        }
      }
    }
  }
}

nav > * {
  height: calc(var(--font-size) * 3.5);
  align-self: center;
}

.col-1 {
  width: calc(100% / 12) * 1;
}
.col-2 {
  width: calc(100% / 12) * 2;
}
.col-3 {
  width: calc(100% / 12) * 3;
}
.col-4 {
  width: calc(100% / 12) * 4;
}
.col-5 {
  width: calc(100% / 12) * 5;
}
.col-6 {
  width: calc(100% / 12) * 6;
}
.col-7 {
  width: calc(100% / 12) * 7;
}
.col-8 {
  width: calc(100% / 12) * 8;
}
.col-9 {
  width: calc(100% / 12) * 9;
}
.col-10 {
  width: calc(100% / 12) * 10;
}
.col-11 {
  width: calc(100% / 12) * 11;
}
.col-12 {
  width: calc(100% / 12) * 12;
}

.defunct-details .table {
  display: flex;
  span ul li {
    list-style: none;
  }
}
.defunct-details .table-description {
  width: 100%;
}
.defunct-details span {
  min-height: 24px;
}

.mat-menu {
  background: none;
}

.mat-expansion-panel-content {
  line-height: 1.1;
}

app-view mat-card {
  margin-bottom: 20px;
}
app-view mat-expansion-panel {
  margin: 20px 0 20px 0 !important;
}
app-lib-view mat-expansion-panel {
  margin: 20px 0 20px 0 !important;
}

mat-grid-list {
  background-color: #0000000d;
  width: 100%;
  mat-grid-title {
    border: 1px solid green;
  }
}

.span-link {
  cursor: pointer;
}
.span-link:hover {
  color: var(--color-action);
}

app-defunct-details {
  mat-panel-description {
    display: block !important;
    padding: var(--padding-large) 0;
    margin: auto;
    mat-divider {
      margin: 20px 0 !important;
    }
    .content {
      color: var(--font-color-primary);
    }
    .defunct-details {
      display: flex;
      width: 100%;
    }
    .defunct-details div {
      display: flex;
      flex-direction: column;
    }
  }
}

app-layout > app-header > header > *,
app-layout > main > *,
app-layout > footer > * {
  width: var(--ui-width);
  max-width: var(--ui-max-width);
  margin-left: auto;
  margin-right: auto;
}

mat-dialog-container > app-dialog-cerfa-create {
  display: inline-block;
  height: auto;
}
mat-dialog-container > app-dialog-cerfa-create > section {
  height: inherit;
}
mat-dialog-container > app-dialog-cerfa-create > section > form.popup-form {
  display: flex;
  height: auto;
  width: auto;
  flex-direction: column;
  justify-content: space-around;
}
mat-dialog-container
  > app-dialog-cerfa-create
  > section
  > form.popup-form
  > div.input {
  margin: calc(var(--font-size) * 1);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
mat-dialog-container
  > app-dialog-cerfa-create
  > section
  > form.popup-form
  > div.input
  > div {
  margin: calc(var(--font-size) * 0.2);
  width: 100%;
}
app-dialog-cerfa-create > section#cerfaView > form.popup-form {
  height: max-content;
}

.cerfa-status-progress-bar {
  display: flex;
  min-height: calc(var(--font-size) * 4);
  align-items: center;
}
.cerfa-status-progress-bar > div.col-2.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cerfa-status-progress-bar > div.col-2 {
  color: #0000008a;
}
.cerfa-status-progress-bar > div.col-3 {
  color: #0000008a;
}
#cdk-accordion-child-0 > div:nth-child(1) {
  margin-right: 16px;
}

app-document-dial.ng-star-inserted > div {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
}
app-document-dial.ng-star-inserted {
  width: inherit;
  height: inherit;
}
app-document-dial.ng-star-inserted > div {
  width: inherit;
  height: inherit;
}
app-document-dial.ng-star-inserted > div > iframe#frame {
  width: 100%;
  height: 100%;
}
app-document-viewer.ng-star-inserted > div {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
}
app-document-viewer.ng-star-inserted {
  width: inherit;
  height: inherit;
}
app-document-viewer.ng-star-inserted > div {
  width: inherit;
  height: inherit;
}
app-document-viewer.ng-star-inserted > div > iframe#frame {
  width: 100%;
  height: 75vh;
}
.popup-title {
  text-align: center;
}
app-defunct-detail {
  mat-panel-description {
    display: block !important;
    padding: var(--padding-large) 0;

    mat-divider {
      margin: 20px 0 !important;
    }
    .content {
      color: var(--font-color-primary);
    }
    .defunct-details {
      display: flex;
      width: 100%;
    }
    .defunct-details div {
      display: flex;
      flex-direction: column;
    }
  }
}

app-beneficiary-details {
  mat-panel-description {
    display: block !important;
    mat-divider {
      margin: 20px 0 !important;
    }
    .content {
      color: var(--font-color-primary);
    }
    .defunct-details {
      display: flex;
      width: 100%;
    }
    .defunct-details div {
      display: flex;
      flex-direction: column;
    }
  }
}

.lie-view-title-card {
  mat-card-title {
    justify-content: left !important;
  }
}

#cerfaView > div.json {
  display: flex;
  border: 1px solid blue;
  > #yellow {
    background-color: yellow;
  }
}
app-list .card-table {
  margin-top: 2rem;
}

app-list .card-table table {
  width: 100%;
}

app-list table .mat-header-cell,
table td.mat-cell {
  // padding: 0.25rem;
  border-bottom-style: none;
  cursor: pointer;
}

app-list tr.mat-row {
  height: auto;
}

app-list .mat-header-row {
  vertical-align: bottom;
}

app-list .mat-row:hover:hover {
  background-color: #d3d3d3;
}

app-list .mat-cell {
  border-color: transparent;
  border-top: 1px solid #dee2e6;
  font-size: 0.9rem;
  font-weight: 300;
}

app-list .mat-row:nth-child(even) {
  background-color: white;
}

app-list .mat-row:nth-child(odd) {
  background-color: #f2f2f2;
}

app-list > mat-card > table > thead > tr.mat-header-row {
  height: 0px;
}

app-list .mat-header-cell {
  font-size: 14px;
  color: #000;
  font-weight: 680;
}

app-list .mat-paginator-icon {
  width: 25px !important;
  fill: currentColor !important;
}

app-list .mat-sort-header-content {
  text-align: left;
}
app-beneficiary-details table .mat-header-cell,
table td.mat-cell {
  padding: 0.25rem;
  border-bottom-style: none;
  cursor: pointer;
}

app-beneficiary-details tr.mat-row {
  height: auto;
}

app-beneficiary-details .mat-header-row {
  vertical-align: bottom;
}

app-beneficiary-details .mat-row:hover:hover {
  background-color: #d3d3d3;
}

app-beneficiary-details .mat-cell {
  border-color: transparent;
  border-top: 1px solid #dee2e6;
  font-size: 0.9rem;
  font-weight: 300;
}

app-beneficiary-details .mat-row:nth-child(even) {
  background-color: white;
}

app-beneficiary-details .mat-row:nth-child(odd) {
  background-color: #f2f2f2;
}

app-beneficiary-details .data-table-list th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: calc(var(--font-size) * 0.3) !important;
}

app-beneficiary-details > mat-card > table > thead > tr.mat-header-row {
  height: 0px;
}

app-beneficiary-details .mat-header-cell {
  font-size: 14px;
  color: #000;
  font-weight: 680;
}

app-beneficiary-details .mat-paginator-icon {
  width: 25px !important;
  fill: currentColor !important;
}

app-beneficiary-details .mat-sort-header-content {
  text-align: left;
}

.panel-table table .mat-header-cell,
table td.mat-cell {
  padding: 0.25rem;
  border-bottom-style: none;
  cursor: pointer;
}

.panel-table tr.mat-row {
  height: auto;
}

.panel-table .mat-header-row {
  vertical-align: bottom;
}

.panel-table .mat-row:hover:hover {
  background-color: #d3d3d3;
}

.panel-table .mat-cell {
  border-color: transparent;
  border-top: 1px solid #dee2e6;
  font-size: 0.9rem;
  font-weight: 300;
}

.panel-table .mat-row:nth-child(even) {
  background-color: white;
}

.panel-table .mat-row:nth-child(odd) {
  background-color: #f2f2f2;
}

.panel-table .data-table-list th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: calc(var(--font-size) * 0.3) !important;
}

.panel-table > mat-card > table > thead > tr.mat-header-row {
  height: 0px;
}

.panel-table .mat-header-cell {
  font-size: 14px;
  color: #000;
  font-weight: 680;
}

.panel-table .mat-paginator-icon {
  width: 25px !important;
  fill: currentColor !important;
}

.panel-table .mat-sort-header-content {
  text-align: left;
}

.panel-table .mat-expansion-panel-description > div {
  width: 100%;
}
.panel-table .mat-panel-content {
  width: 100%;
}
.view-details-infos-table > div span {
  min-height: 24px;
}
app-register-confirmation > mat-card {
  margin: auto;
  width: 50%;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.ara-log {
  background-color: rgba(128, 128, 128, 0.3);
  color: white;
}
.font-green {
  color: green;
}
.font-red {
  color: red;
}
button.mat-menu-item {
  color: var(--color-black);
}
button.mat-menu-item:disabled {
  color: var(--color-text-gray);
}

button[type="title"] {
  font-family: var(--font-family);
  font-size: calc(var(--font-size) * 1.6) !important;
  font-weight: 500;
  border: none;
  background-color: transparent;
}
div.buttonB {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.api-log {
  color: red;
  background-color: #ff111110;
}
.data-table-list th.mat-sort-header:nth-child(1) {
  padding-left: 2px;
}
.data-table-list th.mat-sort-header {
  padding-bottom: calc(var(--font-size) / 4);
}

.header-card-cerfa {
  display: flex !important;
  justify-content: space-between;
}
.mat-divider {
  margin: 8px 0 !important;
}
th.mat-sort-header.table-currency-col-header > div > div {
  text-align: right;
}
button:hover {
  cursor: pointer;
}
.logout-btn:hover {
  cursor: pointer;
  color: var(--color--btn-important);
  background-color: transparent;
}
.mat-paginator-icon:hover {
  color: var(--color--btn-important);
}
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $pink;
}
